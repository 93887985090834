
import { defineComponent, computed, defineAsyncComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  components: {
    ReportBuilderTab: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "GlobalConfigPageReportBuilder" */ '@/components/global-config/report-builder/ReportBuilderTab.vue'
      ),
    ),
    GeotabTab: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "GlobalConfigPageGeotab" */ '@/components/global-config/geotab/GeotabTab.vue'
      ),
    ),
    OpenWeatherMapTab: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "GlobalConfigPageOpenWeatherMap" */ '@/components/global-config/open-weather-map/OpenWeatherMapTab.vue'
      ),
    ),
  },
  setup() {
    const routeToReportBuilder = () => {
      router.push({ name: 'GlobalConfigPageReportBuilder' });
    };
    const routeToGeotab = () => {
      router.push({ name: 'GlobalConfigPageGeotab' });
    };
    const routeToOpenWeatherMap = () => {
      router.push({ name: 'GlobalConfigPageOpenWeatherMap' });
    };

    // Getting the current editing tab from the router path
    const editTab = computed(() => {
      const currPath = router.currentRoute.value.path;

      if (currPath.includes('/global-config/report-builder')) {
        return 'report-builder';
      } else if (currPath.includes('/global-config/geotab')) {
        return 'geotab';
      } else if (currPath.includes('/global-config/open-weather-map')) {
        return 'open-weather-map';
      } else {
        // (If we don't have a tab or we have an invalid tab, redirect to the general tab)
        routeToReportBuilder();
        return 'none';
      }
    });

    return {
      editTab,
      routeToReportBuilder,
      routeToGeotab,
      routeToOpenWeatherMap,
    };
  },
});
